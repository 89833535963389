@import url('variables.css');
@import url('locomotive.scss');

*::-webkit-scrollbar {
	display: none;
}

html {
	background: #000!important;
}

body {
	overflow:hidden
}

main {
	height: 100%;
}

button {
	font-weight: 600 !important;
}

header {
	color: var(--white);
	background: var(--black);
	min-height: 0;
	height: 0;
	z-index: 20;
	transition: all 0.75s;
	will-change: transform !important;
	opacity: 0;
	overflow: hidden;

	&.open {
		min-height: 500px;
		opacity: 1;
	}
}

.swiper {
	overflow: visible !important;
}

.menu-items a {
	perspective: 600px;
	transition: transform 0.1s ease-out;
}

.content-blog h2 {
	margin-top: 20px;
	margin-bottom: 0px;
	font-size: 24px;
	font-weight: 500 !important;
	color: #000 !important;
}

.toggle-shadow { 
	box-shadow: 0 .7px 2.7px 0 #0000001c,0 3px 6px 0 #00000014,0 7.5px 14px 0 #0000000f,0 15px 30px 0 #0000000d,0 25px 58px 0 #00000008;
}
.content-blog h3 {
	margin-top: 14px;
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 500 !important;
	color: #000 !important;

}

.content-blog ul {
	color: black;
}

.content-blog ul li {
	flex-direction: column;
	display: flex;
	font-size: 16px !important;
	color: #767676 !important;
}

.content-blog ul li,
.content-blog p {
	font-size: 18.75px !important;
	line-height: 145%;
	color: #777 !important;
}

.content-blog strong {
	color: #000 !important;
	font-weight: 500 !important;
	;
}

.Toastify__progress-bar-theme--dark {
	background: #d4af37 !important;
}

@media screen and (max-width: 1000px) {
	header {
		&.open {
			min-height: 575px;
			opacity: 1;
		}
	}



	.content-blog h2 {
		font-size: 20px;
		font-weight: 600 !important;

	}

	.content-blog ul li,
	.content-blog p {
		font-size: 16px !important;
		color: #777 !important;


	}

	.content-blog h3 {
		font-size: 18px;

	}
}



.horizontal-container {
	width: 500%;
	height: 100vh;
	display: flex;
	flex-wrap: nowrap;
}

.header-item {
	padding: 40px 100px;
	font-size: var(--h5-size);
	color: var(--offwhite);
	border-bottom: 1px solid var(--offblack);

	&:hover {
		background: var(--secondary);
	}

	&:first-child {
		border-right: 1px solid var(--offblack);
	}
}

@media screen and (max-width: 768px) {

	.header-item {
		padding: 15px 20px;

		&:first-child {
			border-right: 0;
		}
	}
}

h1,h2,h3,h4,h5,h6, .poppins{ 
	font-family: "Poppins", sans-serif!important;
}

*{
	font-family: "Inter", sans-serif!important;
}
h1 {
	font-size: var(--title-size);
}

h2 {
	font-weight: 700!important;
	letter-spacing: 0.5px!important;
}

h3 {
	font-weight: 500!important;
	letter-spacing: 0px!important;
}

p {
	font-weight: 400 !important;
	font-size: 16px !important;
	@media screen and (max-width: 768px) {
		font-size: var(--label-size);
	}
}

body {
	margin: 0;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: -webkit-fill-available;
}



#content {
	z-index: 11;
	position: relative;
	background: var(--white);
	width: -webkit-fill-available;

}

.group {
	display: flex;
	justify-content: center;
	align-items: center;
}

.foreignObject {
	width: 100%;
	height: 100%;
}

#rules h5 { 
	font-size: 18px;
		color: #555;
	font-weight: 600;
}


#rules p { 
	font-size: 18px!important;
	font-weight: 400!important;
	color: #000;
}


footer {
	background: var(--black);
	display: flex;
	color: var(--white);
	bottom: 0;
	gap: 0px;
	z-index: -1;
	padding-bottom: 40px;
	bottom: 0;
	align-items: start;
	width: -webkit-fill-available;

	&>div:first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: -webkit-fill-available;
	}


}

footer p:hover {
	color: white;
	cursor: pointer
}

@media screen and (max-width: 768px) {
	.timeline-vertical>li>hr {
		display: none
	}
	footer {
		padding: 30px 20px;
		flex-direction: column;
		align-items: start;
		justify-content: start;
		gap: 30px;

		>div:first-child {
			display: flex;
			justify-content: start;
			flex-direction: column;
			gap: 30px;
			align-items: start;
		}
	}
}

.svg-container {
      width: 200px;
      height: 150px;
      perspective: 500px;
}

.rectangle {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transform: rotateX(15deg) rotateY(15deg);
      position: relative;
}

.rectangle rect {
      fill: #3498db;
}

.shadow {
      fill: #2980b9;
      opacity: 0.8;
}

.top, .side {
      position: absolute;
      transform-origin: top left;
}

.top {
      transform: rotateX(90deg) translateZ(-15px) translateY(-50%);
}

.side {
      transform: rotateY(-90deg) translateZ(-15px) translateX(-50%);
}



  .button-effect:hover span:first-child {
    transform: translateX(0);
  }

  #menu, img {
  will-change: transform, opacity;
}

/* @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary: #d4af37;
	--secondary: #151c24;

	--black: #000000;
	--offblack: #2c2c2c;
	--white: #ffffff;
	--offwhite: #f2f2f2;

	--heading-font: 'Poppins', sans-serif;
	--text-font: 'Inter', sans-serif;


	--border-radius-sm: 4px;
	--border-radius-md: 8px;
	--border-radius-lg: 12px;

	--spacing-xs: 4px;
	--spacing-sm: 8px;
	--spacing-md: 16px;
	--spacing-lg: 24px;
	--spacing-xl: 32px;

	--box-shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
	--box-shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
	--box-shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);

	--title-size: 2.5rem;
	--header-size: 2rem;
	--paragraph-size: 1.3rem;
	--span-size: 1.1rem;

	--h1-size: 2.5rem;
	--h2-size: 2rem;
	--h3-size: 1.8rem;
	--h4-size: 1.6rem;
	--h5-size: 1.4rem;
	--h6-size: 1.2rem;

	--button-padding-y: 10px;
	--button-padding-x: 20px;
	--button-font-size: 1rem;
}


.font-inter {
	font-family: 'Inter', sans-serif !important;
}
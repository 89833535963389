/* Basic styles */
*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	--bg-color: #000000;
}

.home-title span {
	position: relative;
	overflow: hidden;
	display: block;
	line-height: 1.45;
}

.home-title span::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 101%;
	height: 100%;
	background: white;
	animation: a-ltr-after 1.4s cubic-bezier(.77, 0, .18, 1) forwards;
	transform: translateX(-100%);
}

.home-title span::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 101%;
	height: 100%;
	background: var(--bg-color);
	animation: a-ltr-before 1.4s cubic-bezier(.77, 0, .18, 1) forwards;
	transform: translateX(0);
}

.home-title span:nth-of-type(1)::before,
.home-title span:nth-of-type(1)::after {
	animation-delay: 0s;
}

.home-title span:nth-of-type(2)::before,
.home-title span:nth-of-type(2)::after {
	animation-delay: 0.5s;
}

@keyframes a-ltr-after {
	0% {
		transform: translateX(-100%)
	}

	100% {
		transform: translateX(101%)
	}
}

@keyframes a-ltr-before {
	0% {
		transform: translateX(0)
	}

	100% {
		transform: translateX(200%)
	}
}